import React, {useEffect, useRef, useState} from 'react';
import {Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import s from './SliderGallery.scss';
import {withGlobals} from '../../../globalPropsContext';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../stylesParamsContext';
import {GridType, IGallerySantaProps} from '../../../types/galleryTypes';
import classnames from 'classnames';
import type {Swiper as SwiperClass} from 'swiper/types';
import {SliderGalleryTitle} from './SliderGalleryTitle/SliderGalleryTitle';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import type {SwiperProps} from 'swiper/swiper-react';
import {ArrowsNavigationWrapper} from './ArrowsNavigationWrapper/ArrowsNavigationWrapper';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';
import {ProductItemSlide} from './ProductItemSlide/ProductItemSlide';
import {Experiments} from '../../../constants';
import {useEnvironment, useExperiments, usePanorama, useSentry} from '@wix/yoshi-flow-editor';
import {ProductMediaDataHook} from '../../../common/components/ProductItem/ProductMedia/ProductMedia';
import {PaginationDots} from './PaginationDots/PaginationDots';
import {ConditionalRender} from '../../../category/components/ConditionalRender/ConditionalRender';
import {IPropsInjectedByViewerScript} from '../../../types/sliderGalleryTypes';
import {ISliderGlobalProps} from '../../sliderGlobalStrategy';
import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import {EmptySliderGallery} from './EmptySliderGallery/EmptySliderGallery';
import {useSliderConfiguration} from './hooks/useSliderConfiguration';

export const VisibleSlideClassName = 'VisibleSlideClassName';
export enum SliderGalleryDataHook {
  Root = 'SliderGalleryDataHook.Root',
  Slide = 'SliderGalleryDataHook.Slide',
  SliderAnnouncer = 'SliderGalleryDataHook.SliderAnnouncer',
  Navigation = 'SliderGalleryDataHook.Navigation',
  PaginationDotWrapper = 'SliderGalleryDataHook.PaginationDotWrapper',
}
export type SliderGalleryProps = Omit<
  IPropsInjectedByViewerScript & IGallerySantaProps,
  ISliderGlobalProps['globals']
> &
  ISliderGlobalProps;

/* eslint-disable sonarjs/cognitive-complexity */
export const SliderGallery: React.FC<SliderGalleryProps> = withGlobals((props: SliderGalleryProps) => {
  const {globals, host, onAppLoaded, hideGallery, isLoaded} = props;
  const {loadPrevProductsIfNeeded, loadNextProductsIfNeeded} = globals;

  const {experiments} = useExperiments();
  const {isRTL} = useEnvironment();
  const hideArrowsOnSliderGalleryWithFewerProductsThanColumns = experiments.enabled(
    Experiments.HideArrowsOnSliderGalleryWithFewerProductsThanColumns
  );

  const sliderGalleryInfiniteLoopToggleViewer = experiments.enabled(Experiments.SliderGalleryInfiniteLoopToggleViewer);
  const arrowContainerHeightsFixEnabled = experiments.enabled(Experiments.ArrowContainerHeightsFix);
  const enableSliderTeaser = experiments.enabled(Experiments.EnableSliderTeaserViewer);

  const rootRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const [maxSwiperWidth, setMaxSwiperWidth] = useState<string>('inherit');
  const [swiperCurrentIndex, setSwiperCurrentIndex] = useState<number>(0);
  const [arrowsContainerHeight, setArrowsContainerHeight] = useState<number>();
  const [a11yAnnouncer, setA11yAnnouncer] = useState<Announcer>(null);
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const autoGrid = styles.get(stylesParams.gallery_gridType) === GridType.AUTO;
  const [responsiveSlidesCount, setResponsiveSlidesCount] = useState<number>();

  const {totalProducts} = globals;

  // istanbul ignore next: cant test with jsdom, tested by sled
  const getImageElement = () => {
    return rootRef.current.querySelector(`[data-hook="${ProductMediaDataHook.Images}"]`);
  };

  const minProductWidth = styles.get(stylesParams.gallery_productSize);
  const responsiveLayoutGap = styles.get(stylesParams.gallery_gapSizeColumn);
  const swiperWidth = swiperRef?.current?.clientWidth;

  // istanbul ignore next: cant test with jsdom, tested by sled
  useEffect(() => {
    if (enableSliderTeaser) {
      if (!swiperWidth) {
        return;
      }

      setTimeout(() => swiperRef.current?.classList.remove(s.ssrFix), 0);
      return;
    }

    if (!autoGrid || !swiperWidth) {
      return;
    }

    const slidesCount = Math.floor((swiperWidth + responsiveLayoutGap) / (minProductWidth + responsiveLayoutGap));
    setResponsiveSlidesCount(Math.max(Math.min(slidesCount, totalProducts), 1));
    setTimeout(() => swiperRef.current?.classList.remove(s.ssrFix), 0);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [swiperWidth, minProductWidth, responsiveLayoutGap]);

  useEffect(() => {
    // istanbul ignore next: cant test with jsdom, tested by sled
    const resizeObserver = new ResizeObserver(() => {
      if (enableSliderTeaser) {
        updateImageHeight();
        return;
      }

      const width = rootRef.current?.clientWidth;
      if (width) {
        setMaxSwiperWidth(`${width}px`);
      }

      const imageHeight = getImageElement()?.clientHeight;
      if (imageHeight) {
        setArrowsContainerHeight(imageHeight);
      }
    });

    if (rootRef.current) {
      resizeObserver?.observe(rootRef.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rootRef, rootRef.current]);

  useEffect(() => {
    host.registerToComponentDidLayout(reportAppLoaded);
    setA11yAnnouncer(new Announcer(SliderGalleryDataHook.SliderAnnouncer));
    loadPrevProductsIfNeeded?.(0);

    return () => {
      a11yAnnouncer?.cleanup();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportAppLoaded = () => {
    if (globals.isInteractive) {
      onAppLoaded?.();
    }
  };

  const isEmptyState = (): boolean => {
    const {products, isCategoryVisible} = globals;
    return !products?.length || !isCategoryVisible;
  };

  const sentry = useSentry();
  const panorama = usePanorama();

  /* istanbul ignore next: hard to test while we have to mock swiper */
  const getKeyboardFocusableElements = (element: HTMLElement) => {
    if (!experiments.enabled(Experiments.SliderGalleryDisableThrowOnA11yInitErrors)) {
      const tagSelectors = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
      return [...element.querySelectorAll(`:is(${tagSelectors})`)].filter(
        (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
      );
    }

    try {
      const tagSelectors = 'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])';
      const focusableElements = element.querySelectorAll(`:is(${tagSelectors})`);
      return [...focusableElements].filter((el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'));
    } catch (e) {
      sentry.captureMessage(`Error getting focusable elements from element ${element.innerHTML}`);
      sentry.captureException(e);
      panorama.logger().error(e, {element: element.innerHTML});
    }
  };

  /* istanbul ignore next: hard to test while we have to mock swiper */
  const updateSlidesKeyboardNavigation = (_swiper: SwiperClass) => {
    _swiper.slides.forEach((slide: HTMLAnchorElement, i) => {
      const elements = getKeyboardFocusableElements(slide);
      const tabIndex = _swiper.visibleSlidesIndexes.includes(i) ? 0 : -1;
      elements.forEach((el: HTMLAnchorElement) => (el.tabIndex = tabIndex));
    });
  };

  const renderPlaceholderSlides = () => {
    const numberOfPlaceholders = autoGrid ? 40 : 6;
    return (
      <>
        {Array.from({length: numberOfPlaceholders}, (_, i) => (
          <SwiperSlide key={`slider_placeholder_${i}`} className={s.swiperSlide}>
            <div data-hook={SliderGalleryDataHook.Slide} className={s.productItemSlide}>
              <ProductPlaceholder />
            </div>
          </SwiperSlide>
        ))}
      </>
    );
  };

  const renderSlides = () => {
    const visibleSlidesIndexes = [...(swiper?.visibleSlidesIndexes || [])];
    return globals.products.map((product, i) => (
      <SwiperSlide key={i} className={s.swiperSlide} tabIndex={-1} aria-hidden={!visibleSlidesIndexes.includes(i)}>
        <ProductItemSlide
          dataHook={SliderGalleryDataHook.Slide}
          className={s.productItemSlide}
          product={product}
          index={i}
          a11yAnnouncer={a11yAnnouncer}
        />
      </SwiperSlide>
    ));
  };

  const shouldNotShowSlider = () => {
    const {isCategoryVisible, isEditorMode} = globals;
    return !isLoaded || hideGallery || (!isEditorMode && !isCategoryVisible);
  };

  const shouldLoop = sliderGalleryInfiniteLoopToggleViewer ? styles.get(stylesParams.gallery_sliderInfiniteLoop) : true;
  const {spaceBetween, slidesOffset} = useSliderConfiguration(swiper?.width);
  let slidesCount: number;
  /* istanbul ignore else: remove when merging specs.stores.EnableSliderTeaserViewer */
  if (enableSliderTeaser) {
    slidesCount = useSliderConfiguration(swiper?.width).slidesCount;
  } else {
    slidesCount = autoGrid ? responsiveSlidesCount : styles.get(stylesParams.galleryColumns);
  }

  /* istanbul ignore next: hard to test while we have to mock swiper, will test in sled */
  const getAvailableSpaceForTeasers = (_swiper: SwiperClass) => {
    const slideWidth: number = _swiper.slidesSizesGrid?.[0];
    if (!slideWidth) {
      return null;
    }

    const fullyVisibleSlidesCount = Math.floor(slidesCount);
    const fullyVisibleSlidesSpace = (spaceBetween + slideWidth) * fullyVisibleSlidesCount;
    return _swiper.width - fullyVisibleSlidesSpace + spaceBetween;
  };

  /* istanbul ignore next: hard to test while we have to mock swiper, will test in sled */
  const offsetSnapGridForTeaser = (_swiper) => {
    const snapGridOffsetForTeaser = getAvailableSpaceForTeasers(_swiper) / 2 - slidesOffset;

    _swiper.snapGrid.forEach((_, i) => {
      if (i === 0) {
        return;
      }

      if (i === _swiper.snapGrid.length - 1 && !shouldLoop) {
        return;
      }

      _swiper.snapGrid[i] -= snapGridOffsetForTeaser;
    });
  };

  /* istanbul ignore next: hard to test while we have to mock swiper, will test in sled */
  const onSnapGridLengthChange = (_swiper) => {
    if (!slidesCount) {
      return;
    }

    setTimeout(() => offsetSnapGridForTeaser(_swiper), 100);
  };

  const updateImageHeight = () => {
    const imageElement = rootRef.current.querySelector(`[data-hook="${ProductMediaDataHook.Images}"]`);
    const imageHeight = imageElement?.clientHeight;
    if (imageHeight) {
      setArrowsContainerHeight(imageHeight);
    }
  };

  const {isCategoryVisible, isEditorMode} = globals;
  if (isEditorMode && !isCategoryVisible) {
    return <EmptySliderGallery />;
  }

  if (shouldNotShowSlider()) {
    return null;
  }

  const enoughProductsToNavigate = totalProducts > slidesCount;
  const productsAmountPerSwipe = 1;
  const swiperConfig: SwiperProps = {
    modules: [Mousewheel],
    onSwiper: setSwiper,
    slidesPerView: 'auto',
    slideVisibleClass: VisibleSlideClassName,
    threshold: 2,
    updateOnWindowResize: true,
    loop: enoughProductsToNavigate && shouldLoop,
    dir: isRTL ? 'rtl' : 'ltr',
    mousewheel: {forceToAxis: true, sensitivity: 0.1, releaseOnEdges: true},
    onSlideNextTransitionStart: (_swiper) => loadNextProductsIfNeeded(_swiper.realIndex),
    onSlidePrevTransitionStart: (_swiper) => loadPrevProductsIfNeeded(_swiper.realIndex),
    onScroll: (_swiper) => setSwiperCurrentIndex(_swiper.realIndex),
    onTransitionStart: (_swiper) => setSwiperCurrentIndex(_swiper.realIndex),
    onActiveIndexChange: updateSlidesKeyboardNavigation,
    onResize: updateSlidesKeyboardNavigation,
    ...(enableSliderTeaser
      ? {
          slidesPerView: slidesCount ?? 'auto',
          spaceBetween,
          slidesOffsetBefore: slidesOffset,
          slidesOffsetAfter: slidesOffset,
          resizeObserver: true,
          loopAdditionalSlides: 1,
          onSnapGridLengthChange,
        }
      : {slidesPerView: 'auto', threshold: 2}),
  };

  const areArrowsNeeded = hideArrowsOnSliderGalleryWithFewerProductsThanColumns ? enoughProductsToNavigate : true;
  const hasPrevItems = shouldLoop || !swiper?.isBeginning;
  const hasNextItems = shouldLoop || !swiper?.isEnd;

  const inlineCssVars = enableSliderTeaser
    ? {'--totalNumberOfProducts': totalProducts}
    : {'--maxWidth': maxSwiperWidth, '--responsiveSlidesCount': responsiveSlidesCount};

  return (
    <div data-hook={SliderGalleryDataHook.Root} className={s.root} ref={rootRef}>
      <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
      <SliderGalleryTitle />
      <div style={inlineCssVars as React.CSSProperties} className={classnames(s.swiperContainer)}>
        <ArrowsNavigationWrapper
          arrowsContainerHeight={arrowsContainerHeight}
          hasPrevItems={areArrowsNeeded && hasPrevItems}
          hasNextItems={areArrowsNeeded && hasNextItems}
          navigateNext={() =>
            hideArrowsOnSliderGalleryWithFewerProductsThanColumns
              ? swiper?.slideNext()
              : enoughProductsToNavigate && swiper?.slideNext()
          }
          navigatePrev={() =>
            hideArrowsOnSliderGalleryWithFewerProductsThanColumns
              ? swiper?.slidePrev()
              : enoughProductsToNavigate && swiper?.slidePrev()
          }>
          <Swiper
            className={classnames(s.swiperRoot, {
              [s.autoGrid]: autoGrid,
              [s.enableSliderTeaser]: enableSliderTeaser,
              [s.ssrFix]: enableSliderTeaser || autoGrid,
            })}
            role={'group'}
            {...swiperConfig}
            watchSlidesProgress={true}
            ref={swiperRef}>
            {isEmptyState() ? renderPlaceholderSlides() : renderSlides()}
          </Swiper>
          {!arrowContainerHeightsFixEnabled && (
            <ConditionalRender by={'gallery_showSliderPaginationDots'}>
              <PaginationDots
                swiperCurrentIndex={swiperCurrentIndex}
                totalProducts={totalProducts}
                slidesCount={slidesCount}
                shouldLoop={shouldLoop}
                productsAmountPerSwipe={productsAmountPerSwipe}
              />
            </ConditionalRender>
          )}
        </ArrowsNavigationWrapper>
        {arrowContainerHeightsFixEnabled && (
          <ConditionalRender by={'gallery_showSliderPaginationDots'}>
            <PaginationDots
              swiperCurrentIndex={swiperCurrentIndex}
              totalProducts={totalProducts}
              slidesCount={slidesCount}
              shouldLoop={shouldLoop}
              productsAmountPerSwipe={productsAmountPerSwipe}
            />
          </ConditionalRender>
        )}
      </div>
    </div>
  );
});
